<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="hazardMachineItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="hazardMachineFlag"
            label="유해위험기계기구 여부"
            v-model="searchParam.hazardMachineFlag"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-tree-table
          title="설비유형 목록"
          parentProperty="upEquipmentTypeCd"
          customID="equipmentTypeCd"
          :columns="gridClass.columns"
          :data="gridClass.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <q-form ref="editForm">
          <c-card title="설비유형 상세정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="등록" icon="add" @btnClicked="addClass" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="equipClassData"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveClass"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  required
                  :editable="editable && dataeditable"
                  label="설비유형 명칭"
                  name="equipmentTypeName"
                  v-model="equipClassData.equipmentTypeName">
                </c-text>
              </div>
              <!-- <div class="col-6">
                <c-text
                  required
                  :editable="editable && dataeditable"
                  :disabled="updateMode"
                  label="설비유형 코드"
                  name="equipmentTypeCd"
                  v-model="equipClassData.equipmentTypeCd">
                </c-text>
              </div> -->
              <div class="col-6">
                <c-text
                  :afterIcon="editable ? [
                    { name: 'search', click: true, callbackName: 'selectUpType', color: 'light-blue' },
                    { name: 'close', click: true, callbackName: 'removeUpType', color: 'red' }
                  ] : null"
                  :disabled="true"
                  :editable="editable && dataeditable"
                  label="상위 설비유형"
                  name="upEquipmentTypeName"
                  v-model="equipClassData.upEquipmentTypeName"
                  @selectUpType="() => { isUpTypeOpen = true }"
                  @removeUpType="removeUpType">
                </c-text>
              </div>
              <div class="col-6">
                <c-plant 
                  required
                  type="edit" 
                  :disabled="updateMode" 
                  :editable="editable && dataeditable"
                  name="plantCd" 
                  v-model="equipClassData.plantCd" 
                />
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="일상점검 대상"
                  name="dailyFlag"
                  v-model="equipClassData.dailyFlag"
                />
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="equipClassData.useFlag"
                />
              </div>
              <div class="col-4">
                <c-radio
                  :editable="editable && dataeditable"
                  :comboItems="hazardMachineItems"
                  label="유해위험기계기구 여부"
                  name="hazardMachineFlag"
                  v-model="equipClassData.hazardMachineFlag">
                </c-radio>
              </div>
              <div class="col-12">
                <c-textarea
                  :counter="true"
                  :maxlength="200"
                  :editable="editable && dataeditable"
                  :rows="5"
                  label="비고"
                  name="remarks"
                  v-model="equipClassData.remarks">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
    <q-dialog
      ref="upTypeDialog"
      persistent
      v-model="isUpTypeOpen">
      <q-card class="menu-card" style="width: 600px">
        <q-card-section class="row items-center q-pb-none px-2 py-1 bg-orange-custom text-white">
          <div class="text-h6">{{$language('상위 설비유형')}}}</div>
          <q-space></q-space>
          <q-btn icon="done" flat round dense @click="selectUpType"></q-btn>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-item>
          <q-item-section>
            <q-item-label caption>
              <q-chip outline square color="primary" text-color="white">
                <q-icon name="check"></q-icon>{{upTypeNm}}
              </q-chip>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-separator></q-separator>

        <q-card-section class="uptype-content p-2">
          <q-tree
            ref="upTypeTree"
            :nodes="upTypeTree"
            node-key="equipmentTypeCd"
            label-key="equipmentTypeName"
            children-key="children"
            no-nodes-label="상위유형이 없습니다."
            no-results-label=""
            :default-expand-all="false"
            selected-color="primary"
            :selected.sync="selectedUpType"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import arrayToTree from 'array-to-tree';
export default {
  name: 'equipment-class',
  data() {
    return {
      gridClass: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'dailyFlagName',
            field: 'dailyFlagName',
            label: '일상점검 대상',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'hazardMachineFlagNm',
            field: 'hazardMachineFlagNm',
            label: '유해위험기계기구 여부',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'useFlagNm',
            field: 'useFlagNm',
            label: '사용여부',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        height: '362px'
      },
      searchParam: {
        plantCd: null,
        hazardMachineFlag: null,
        useFlag: 'Y',
      },
      equipClassData: {
        plantCd: null,
        equipmentTypeName: '',
        equipmentTypeCd: '',
        upEquipmentTypeCd: '',
        upEquipmentTypeName: '',
        remarks: '',
        hazardMachineFlag: 'N',
        useFlag: 'Y',
        dailyFlag: 'N',
      },
      upTypeTree: [],
      upTypeNm: '상위유형을 선택하세요.',
      isUpTypeOpen: false,
      selectedUpType: null,
      selectedSysMenu: {},
      listUrl: '',
      insertUrl: '',
      updateUrl: '',
      checkUrl: '',
      saveable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      useFlagItems: [],
      hazardMachineItems: [],
      isSave: false,
      saveUrl: transactionConfig.mdm.equipment.class.insert.url,
      saveType: 'POST',
    };
  },
  computed: {
  },
  watch: {
    selectedUpType() {
      if (this.selectedUpType) {
        let data = this.$refs['upTypeTree'].getNodeByKey(this.selectedUpType);
        this.upTypeNm = data.equipmentTypeName;
      } else {
        this.upTypeNm = '상위유형을 선택하세요.';
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.hazardMachineItems = [
        { code: 'Y', codeName: this.$language('유해위험기계기구') },
        { code: 'N', codeName: this.$language('일반') },
      ]
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;

      // api scope
      this.listUrl = selectConfig.mdm.equipment.class.list.url;
      this.detailUrl = selectConfig.mdm.equipment.class.get.url;
      this.insertUrl = transactionConfig.mdm.equipment.class.insert.url;
      this.updateUrl = transactionConfig.mdm.equipment.class.update.url;
      this.checkUrl = selectConfig.mdm.equipment.class.check.url;

      // list scope
      this.getList();
    },
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upEquipmentTypeCd',
        customID: 'equipmentTypeCd',
      });
      return tree;
    },
    selectUpType() {
      if (this.selectedUpType) {
        let data = this.$refs['upTypeTree'].getNodeByKey(this.selectedUpType);
        this.equipClassData.upEquipmentTypeCd = data.equipmentTypeCd;
        this.equipClassData.upEquipmentTypeName = data.equipmentTypeName;
        this.$refs['upTypeDialog'].hide();
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '상위 유형을 선택하세요.', 
          type: 'warning', // success / info / warning / error
        });
      }
    },
    removeUpType() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '상위유형이 없는 경우 최상위 유형로 들어가게 됩니다. 진행하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.equipClassData.upEquipmentTypeCd = null;
          this.equipClassData.upEquipmentTypeName = null;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getList() {
      this.reset();
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.saveable = false;
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridClass.data = _result.data;
        // this.upTypeTree = this.convertTree(this.$_.filter(_result.data, { upEquipmentTypeCd: '' }));
        this.upTypeTree = this.convertTree(_result.data);
      },);
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.updateMode = true; 
      this.$http.url = this.$format(this.detailUrl, row.equipmentTypeCd, row.plantCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.equipClassData = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addClass() {
      this.saveable = true;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.equipClassData = {
        plantCd: this.searchParam.plantCd,
        equipmentTypeName: '',
        equipmentTypeCd: '',
        upEquipmentTypeCd: this.equipClassData.equipmentTypeCd,
        upEquipmentTypeName: this.equipClassData.equipmentTypeName,
        remarks: '',
        hazardMachineFlag: 'N',
        useFlag: 'Y',
        dailyFlag: 'N',
      };
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        plantCd: null,
        equipmentTypeName: '',
        equipmentTypeCd: '',
        upEquipmentTypeCd: '',
        upEquipmentTypeName: '',
        remarks: '',
        hazardMachineFlag: 'Y',
        useFlag: 'Y',
        dailyFlag: 'N',
      };
    },
    saveClass() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크 
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
      this.rowClick({ plantCd: result.data.plantCd, equipmentTypeCd: result.data.equipmentTypeCd })
    },
  }
};
</script>
<style lang="sass">
.uptype-content
  height: 100%
  max-height: 600px !important
  min-height: 500px !important
  overflow-y: auto
</style>